import { Vector3 } from 'three';

export enum ColorMode {
  Gauge = 1,
  Value = 2,
  Title = 3
}
export enum ValueMode {
  Gauge = 1,
  Value = 2,
  Time = 3,
  TimeAndValue = 4
}

export enum PositionOnMachine {
  TopLeft = 1,
  TopCenter = 2,
  TopRight = 3,
  MiddleLeft = 4,
  MiddleCenter = 5,
  MiddleRight = 6,
  BottomLeft = 7,
  BottomCenter = 8,
  BottomRight = 9,
}

export interface FactoryLayoutOptions {
  machineOpacity: number;
  machineColor: string;

  backgroundColor: string;
  backgroundColorLight: string;

  grid: boolean;
  gridColor: string;

  statuses: StatusDefinition[];
  fontSize: number;
  dashboardURLpart1: string;
  dashboardURLpart2: string;
  alarmIconSize: number;
  wrenchIconSize: number;
  restrictedAccessIconSize: number;

  showBase: boolean;
  baseSize: number;

  showLamp: boolean;
  lampSize: number;

  emissiveStatusLight: boolean;
  showAllMaintenance: boolean;

  floorHeight: number;
  floorOffset: number;
  cameraPosition: Vector3;

  gaugeTypes: MetricTypeDefinition[];

  notOnlineStatusFilter: string;
  restrictedAccessColor: string;
  startedMaintenanceColor: string;
  plannedMaintenanceColor: string;
  maintenanceStartedStatus: number;
  maintenanceHistoryStatus: number;

  //labels
  workOrderTxt: string;
  workOrderWidth: number;
  partTxt: string;
  operationTxt: string;
  operatorTxt: string;
  startDateTxt: string;
  endDateTxt: string;
  progressPartsTxt: string;
  scrapQuantityTxt: string;
  progressTimeTxt: string;
  planEndTxt: string;
  departmentTxt: string;
  teamTxt: string;
  allTxt: string;
  unassignedTxt: string;
  floorTxt: string;

  //setup inspection
  setupInspectionMachines: string;
  setupInspectionDashboard: string;

  siStatusTxt: string;
  siStatusWidth: number;
  siOrderTxt: string;
  siOrderWidth: number;
  siMachineTxt: string;
  siMachineWidth: number;
  siReadytTxt: string;
  siCompletedTxt: string;
  siItemNoTxt: string;
  siDrawingTxt: string;

  actualCameraPosition: Vector3 | null;

  actualControlsTargetPosition: Vector3 | null;
  use2DMode: boolean;
  defaultFloor: number;
  disable3dModels: boolean;
  useHtmlLabels: boolean;
}

export type MeshGeometry = {
  width: number;
  height: number;
  depth: number;
  rotationY: number;

  center: Vector3;

  //corners according to initial camera position
  //F=far, N=near
  //B=bottom, T=top
  //L=left, R=right
  cornerFBR: Vector3;
  cornerFBL: Vector3;
  cornerNBL: Vector3;
  cornerNBR: Vector3;

  cornerFTR: Vector3;
  cornerFTL: Vector3;
  cornerNTL: Vector3;
  cornerNTR: Vector3;
};

export const defaults: FactoryLayoutOptions = {
  floorHeight: 500,
  floorOffset: 100,
  backgroundColor: '#000000',
  backgroundColorLight: '#d9dee9',
  statuses: [],
  fontSize: 40,
  dashboardURLpart1: '/dashboard/db/',
  dashboardURLpart2: '?var-sensor=',
  alarmIconSize: 5,
  wrenchIconSize: 6,
  restrictedAccessIconSize: 6,
  showBase: true,
  baseSize: 10,
  showLamp: true,
  lampSize: 10,
  emissiveStatusLight: true,
  machineColor: '#000000',
  machineOpacity: 0.8,
  grid: true,
  gridColor: '#ffffff',
  cameraPosition: new Vector3(1000, 1500, 1000),
  gaugeTypes: [],
  notOnlineStatusFilter: '',
  showAllMaintenance: false,
  restrictedAccessColor: '#edc06b',
  startedMaintenanceColor: '#FF780A',
  plannedMaintenanceColor: '#ECECE8',
  workOrderTxt: '',
  partTxt: '',
  operationTxt: '',
  operatorTxt: '',
  startDateTxt: '',
  endDateTxt: '',
  progressPartsTxt: '',
  scrapQuantityTxt: '',
  progressTimeTxt: '',
  planEndTxt: '',
  departmentTxt: '',
  teamTxt: '',
  floorTxt: 'Floor',
  allTxt: '',
  unassignedTxt: '',
  maintenanceStartedStatus: 0,
  maintenanceHistoryStatus: 0,
  workOrderWidth: 80,
  setupInspectionMachines: '',
  setupInspectionDashboard: '/dashboard/db/setup-inspection?chromeless=true&var-parentDashName=Supervisor%20Menu&var-parentDashSlug=supervisor-menu',
  siStatusTxt: 'Status',
  siOrderTxt: 'OrderPosOp',
  siMachineTxt: 'Machine',
  siReadytTxt: 'Ready',
  siCompletedTxt: 'Completed',
  siItemNoTxt: 'Item N',
  siDrawingTxt: 'Drawing',
  siStatusWidth: 100,
  siOrderWidth: 100,
  siMachineWidth: 100,
  actualCameraPosition: null,
  actualControlsTargetPosition: null,
  use2DMode: false,
  defaultFloor: 0,
  disable3dModels: false,
  useHtmlLabels: false
};

export type SceneProps = {
  machines: LayoutMachine[];
  options: FactoryLayoutOptions;
  layoutConfiguration: LayoutConfiguration;
  showMaintenance: boolean;
  setupInspections: SetupInspection[];
  selectedFloor: number;
};

export type OrderDetailsPanelProps = {
  show: boolean;
  machine: LayoutMachine;
  options: FactoryLayoutOptions;
  setupInspections: SetupInspection[];
};

export type MetricProps = {
  metric: MetricValue;
  show: boolean;
};

export type MetricPanelProps = {
  show: boolean;
  machine: LayoutMachine;
};

export type LayoutMachine = {
  orgItemId: number;
  name: string;
  sensorId: string;
  posX: number;
  posY: number;
  posZ: number;
  width: number;
  height: number;
  depth: number;
  color: string;
  status: string;
  URL: string;
  alarm: boolean;
  face: string;
  rotate: number;
  floor: number;
  static: boolean; // Static box, not machine with status. For object not present in OrganizationItems
  metric: MetricValue[];
  contexts: OrderContext[];
  type: string; //mazak, fanuc, etc.
  department: string; //milling, turning, office, etc.
  visible: boolean; //false when filtering is applied
  mms: MMSData | null;
  team: string;
  path: string;
  scale: number;
  animations: MachineAnimationConfig[];
  hasImage: boolean;
  showERPData: boolean;
  restrictedAccess: boolean;
  metricSprites: THREE.Sprite[];
};

export type MachineAnimationConfig = {
  id: number;
  status: string[];
}

export type MMSData = {
  startDate: string;
  endDate: string;
  shortDescription: string;
  description: string;
  shutdownRequired: boolean;
  started: boolean;
  history: boolean;
  number: number;
};

export type MachineStatus = {
  status: string;
  sensorId: string;
  time: string;
  alarm: number;
};

export type StatusDefinition = {
  status: string;
  color: string;
};

export type MetricTypeDefinition = {
  tagType: string;
  displayName: string;
  min: number;
  max: number;
  unit: string;
  color: string;
  thresholds: ThresholdDefinition[];
  decimals: number;
  colorMode: ColorMode;
  valueMode: ValueMode;
  expression: string;
  timeFormat: string;
  sensorIdRegEx: string;
  defaultGaugeColor: string;
  defaultValueColor: string;
  defaultTitleColor: string;
  displayOnMachine: boolean;
  positionOnMachine: PositionOnMachine;
  fontSize: number;
};

export type ThresholdDefinition = {
  value: number;
  color: string;
};

export type MetricValue = {
  value: string;
  sensorId: string;
  time: string;
  timeStr: string;
  type: string;
  definition: MetricTypeDefinition;
  index: number;
};

export type OrderContext = {
  sensorId: string;
  wo: string;
  pos: string;
  posName: string;
  op: string;
  opName: string;
  operator: string;
  from: string;
  to: string;
  goodQty: number;
  scrapQty: number;
  orderedQty: number;
  opPlanStop: string;
  timePerItem: number;
  setupTime: number;
  shiftGoodQty: number;
  shiftScrapQty: number;
  elapsedTime: number; //minutes?
  totalGanttTime: number; // hours - 5.87-> 5 hours and 87% of one full hour
};

export type SetupInspection = {
  Status: string;
  OrderPositionOperation: string;
  Machine: string;
  Ready: string;
  Completed: string;
  ItemNo: string;
  Drawing: string;
};

export type FloorConfiguration = {
  url: string;
  floor: number;
  name: string;
}

export type LayoutConfiguration = {
  mapHeight: number;
  mapWidth: number;
  floors: FloorConfiguration[];
}

export type MenuProps = {
  filterListDepartment: string[];
  filterListTeam: string[];
  floorList: any[];
  fullscreenTargetId: string;
  selectedDepartment: string;
  selectedTeam: string;
  showMaintenance: boolean;
  selectedFloor: number;
  onSelectDepartmentFilter(filterValue: string): any;
  onSelectTeamFilter(filterValue: string): any;
  onSelectFloor(floorValue: number): any;
  onToggleShowMaintenance(value: boolean): any;
  options: FactoryLayoutOptions;
};
