import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { FactoryLayoutOptions, LayoutMachine, MachineStatus, MetricValue, OrderContext, MMSData, MachineAnimationConfig, LayoutConfiguration, FloorConfiguration, SetupInspection, ColorMode, ValueMode, MetricTypeDefinition } from 'types';
import ThreeScene from 'Scene';
import { loadPluginCss } from '@grafana/runtime';
import Menu from 'Menu';
import { OptionsHelper, GenericHelper, TimeHelper } from './common';

interface Props extends PanelProps<FactoryLayoutOptions> { }

export class FactoryLayoutPanel extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValueDepartment: 'ALL',
      filterValueTeam: 'ALL',
      showMaintenance: false,
      selectedFloor: this.props.options.defaultFloor
    };
  }

  machines: LayoutMachine[] = [];
  setupInspections: SetupInspection[] = [];
  layoutConfiguration: any = {};
  cssLoaded = false;
  filterListDepartment: string[] = [];
  filterListTeam: string[] = [];
  state: any = {
    filterValueDepartment: 'ALL',
    filterValueTeam: 'ALL',
    showMaintenance: false,
    selectedFloor: 0
  };

  render() {
    if (!this.cssLoaded) {
      this.cssLoaded = true;
      loadPluginCss({
        dark: 'plugins/factory-layout-panel/css/factory_layout_base.css',
        light: 'plugins/factory-layout-panel/css/factory_layout_light.css',
      }).then(() => this.setState({ cssLoaded: true }));
      return null;
    }

    if (this.props.data.state === 'Done') {
      this.getMachinesFromQuery();
      if (this.machines.length === 0) {
        return null;
      }

      this.applyMachineStatus();
      this.applyMetricData();
      this.applyConfiguration();
      this.applyContexts();
      this.applySetupInspection();
    } else if (this.machines.length > 0) {
      this.machines = this.applyFilterOnMachines(this.machines);
    }

    const { options } = this.props;
    const fullscreenTargetId = 'factory_layout_' + this.props.id;
    return (
      <div id={fullscreenTargetId} style={{ width: '100%', height: '100%', position: 'relative' }}>
        <ThreeScene machines={this.machines}
          layoutConfiguration={this.layoutConfiguration}
          options={options}
          showMaintenance={this.state.showMaintenance}
          setupInspections={this.setupInspections}
          selectedFloor={this.state.selectedFloor} />
        <Menu
          fullscreenTargetId={fullscreenTargetId}
          filterListDepartment={this.filterListDepartment}
          filterListTeam={this.filterListTeam}
          floorList={this.layoutConfiguration.floors}
          selectedFloor={this.state.selectedFloor}
          selectedDepartment={this.state.filterValueDepartment}
          selectedTeam={this.state.filterValueTeam}
          showMaintenance={this.state.showMaintenance}
          onSelectDepartmentFilter={e => this.handleFilterDepartment(e)}
          onSelectTeamFilter={e => this.handleFilterTeam(e)}
          onToggleShowMaintenance={e => this.handleToggleShowMaintenance(e)}
          onSelectFloor={e => this.handleFloorSelect(e)}
          options={options}
        />
      </div>
    );
  }

  handleFilterDepartment(filterValue: string) {
    this.setState({ filterValueDepartment: filterValue });
  }
  handleFilterTeam(filterValue: string) {
    this.setState({ filterValueTeam: filterValue });
  }
  handleFloorSelect(filterValue: number) {
    this.setState({ selectedFloor: filterValue });
  }

  handleToggleShowMaintenance(val: boolean) {
    this.setState({ showMaintenance: val });
  }

  buildFilterListDepartment(machines: LayoutMachine[]): string[] {
    const listItems: string[] = [];

    machines.forEach(m => {
      if (m.department) {
        listItems.push(m.department);
      } else {
        listItems.push('Other');
      }
    });

    return listItems.filter((x, i, a) => a.indexOf(x) === i).sort();
  }

  buildFilterListTeam(machines: LayoutMachine[]): string[] {
    const listItems: string[] = [];

    machines.forEach(m => {
      if (m.team) {
        listItems.push(m.team);
      } else {
        listItems.push('Other');
      }
    });

    return listItems.filter((x, i, a) => a.indexOf(x) === i).sort();
  }

  applyContexts() {
    const contextsData = this.getContextsFromQuery();
    if (contextsData.length === 0) {
      return;
    }
    this.machines.forEach(m => {
      if (m.showERPData) {
        const contexts = contextsData.filter(d => d.sensorId === m.sensorId);
        if (contexts) {
          m.contexts = contexts;
        }
      }
    });
  }

  applySetupInspection() {
    const setupinspections = this.getSetupinspectionFromQuery();
    if (setupinspections.length === 0) {
      return;
    }
    this.setupInspections = setupinspections;
  }

  applyConfiguration() {
    const configurationData = this.getConfigurationFromQuery();
    this.layoutConfiguration = configurationData;
  }

  applyMetricData() {
    const gData: MetricValue[] = this.getMetricData();
    if (gData.length === 0) {
      return;
    }
    this.machines.forEach(m => {
      const metric = gData.filter(d => d.sensorId === m.sensorId);
      if (metric) {
        m.metric = metric;
      }
    });
  }
  getMetricData() {
    const metricData: MetricValue[] = [];
    const metricTables = this.props.data.series.filter(s => s.refId === "C" || s.refId?.startsWith("Metric"));
    if (metricTables !== undefined) {
      for (let table of metricTables) {
        const colSensorIDValues = GenericHelper.getFieldValuesOrNull(table.fields, "SensorID");
        const colTypeValues = GenericHelper.getFieldValuesOrNull(table.fields, "Type");

        const colStringValues = GenericHelper.getFieldValuesOrNull(table.fields, "StringValue");
        const colIntValues = GenericHelper.getFieldValuesOrNull(table.fields, "IntValue");
        const colFloatValues = GenericHelper.getFieldValuesOrNull(table.fields, "FloatValue");

        if (colSensorIDValues === null) {
          console.log(`SensorID is missing for metric data (refId=${table.refId})`);
          continue;
        }
        if (colTypeValues === null) {
          console.log(`Type is missing for metric data (refId=${table.refId})`);
          continue;
        }
        if (colStringValues === null && colIntValues === null && colFloatValues === null) {
          console.log(`Value is missing for metric data (refId=${table.refId})`);
          continue;
        }

        table.fields[0].values.toArray().forEach((time: string, i) => {
          const tagType = colTypeValues[i];
          const sensorId = colSensorIDValues[i];

          function hasSensorIdRegEx(md: MetricTypeDefinition): boolean {
            if (md.sensorIdRegEx && md.sensorIdRegEx !== null && md.sensorIdRegEx.trim().length > 0) {
              return true;
            }
            else {
              return false;
            }
          }
          function isForSensorId(md: MetricTypeDefinition, sensorId: string): boolean {
            if (hasSensorIdRegEx(md)) {
              let regExp = new RegExp(md.sensorIdRegEx);
              return regExp.test(sensorId);
            }
            else {
              return false;
            }
          }

          let metricDefinition = this.props.options.gaugeTypes.find(g => g.tagType === tagType && isForSensorId(g, sensorId));
          if (!metricDefinition) {
            metricDefinition = this.props.options.gaugeTypes.find(g => g.tagType === tagType && !hasSensorIdRegEx(g));
          }

          if (metricDefinition) {
            metricDefinition.colorMode = metricDefinition.colorMode ? metricDefinition.colorMode : ColorMode.Gauge;
            metricDefinition.valueMode = metricDefinition.valueMode ? metricDefinition.valueMode : ValueMode.Gauge;
            metricDefinition.expression = metricDefinition.expression ? metricDefinition.expression : '';
            metricDefinition.defaultGaugeColor = metricDefinition.defaultGaugeColor ? metricDefinition.defaultGaugeColor : '#ffffff';
            metricDefinition.defaultValueColor = metricDefinition.defaultValueColor ? metricDefinition.defaultValueColor : '#ffffff';
            metricDefinition.defaultTitleColor = metricDefinition.defaultTitleColor ? metricDefinition.defaultTitleColor : '#ffffff';

            const stringValue = colStringValues !== null && colStringValues[i] !== null ? colStringValues[i] : null;
            const intValue = colIntValues !== null && colIntValues[i] !== null ? colIntValues[i] : null;
            const floatValue = colFloatValues !== null && colFloatValues[i] !== null ? colFloatValues[i].toFixed(metricDefinition.decimals) : null;

            let value: string | null =
              stringValue !== null ? stringValue :
                intValue !== null ? intValue.toString() :
                  floatValue !== null ? floatValue.toString() :
                    null;

            const timeStr: string = TimeHelper.formatUnixTime(Number(time), metricDefinition.timeFormat, this.props.timeZone);
            if (metricDefinition.expression !== null && metricDefinition.expression.trim().length > 0) {

              let expression = metricDefinition.expression.trim();
              expression = expression
                .replace(/{Value}/g, value ?? "")
                .replace(/{Time}/g, timeStr)
                .replace(/{Type}/g, tagType)
                .replace(/{SensorID}/g, sensorId);

              try {
                const evaluatedResult = Function(`"use strict";return ${expression}`)();
                value = evaluatedResult.toString();
                if (GenericHelper.isNumber(value ?? "")) {
                  value = Number(value).toFixed(metricDefinition.decimals);
                }
              }
              catch (e) {
                value = expression;
              }
            }

            if (value !== null) {
              const v: MetricValue = {
                time: time,
                timeStr: timeStr,
                type: tagType,
                sensorId: sensorId,
                value: value,
                definition: metricDefinition,
                index: this.props.options.gaugeTypes.findIndex(g => g.tagType === tagType)
              };
              metricData.push(v);
            }
          }
        });
      }
    }
    metricData.sort((a, b) => a.index - b.index);
    return metricData;
  }

  applyMachineStatus() {
    const statuses: MachineStatus[] = this.getLastStatuses();
    if (statuses.length === 0) {
      return;
    }
    this.machines.forEach(m => {
      const status = statuses.find(s => s.sensorId === m.sensorId);
      if (!status || !status.status) {
        m.color = '#000000';
        m.status = 'UNKNOWN';
      } else {
        m.color = this.FindStatusColor(status.status);
        m.status = status.status;
        m.alarm = status.alarm > 0;
      }
    });
  }

  FindStatusColor(status: string): string {
    const color = '#000000';
    const statusColor = this.props.options.statuses.find(s => s.status.trim().toUpperCase() === status.trim().toUpperCase());
    if (statusColor !== undefined) {
      return statusColor.color;
    }
    return color;
  }

  getLastStatuses(): MachineStatus[] {
    const statuses: MachineStatus[] = [];
    const table = this.props.data.series.find(s => s.refId === "B" || s.refId === "Status");
    if (table !== undefined && table.fields.length > 0) {
      const colSensor = table.fields[1].values.toArray();
      const colStatus = table.fields[2].values.toArray();
      const colAlarm = table.fields[3].values.toArray();
      table.fields[0].values.toArray().forEach((r: string, i) => {
        const s: MachineStatus = {
          sensorId: colSensor[i],
          status: colStatus[i],
          alarm: this.checkOnlineStatus(colStatus[i], colAlarm[i]),
          time: r,
        };
        statuses.push(s);
      });
    }
    return statuses;
  }

  getMachinesFromQuery() {
    let machines: LayoutMachine[] = [];
    const table = this.props.data.series.find(s => s.refId === 'A' || s.refId === "LayoutObject");
    if (table !== undefined && table.fields.length > 0) {
      const colPosX = table.fields.filter(a => a.name === "PosX")[0].values.toArray();
      const colPosY = table.fields.filter(a => a.name === "PosY")[0].values.toArray();
      const colWidth = table.fields.filter(a => a.name === "Width")[0].values.toArray();
      const colHeight = table.fields.filter(a => a.name === "Height")[0].values.toArray();
      const colDepth = table.fields.filter(a => a.name === "Depth")[0].values.toArray();
      const colFace = table.fields.filter(a => a.name === "Face")[0].values.toArray();
      const colFloor = table.fields.filter(a => a.name === "Floor")[0].values.toArray();
      const colName = table.fields.filter(a => a.name === "Name")[0].values.toArray();
      const colRotate = table.fields.filter(a => a.name === "Rotate")[0].values.toArray();
      const colSensor = table.fields.filter(a => a.name === "SensorID")[0].values.toArray();
      const colType = table.fields.filter(a => a.name === "TypeID")[0].values.toArray();
      const colURL = table.fields.filter(a => a.name === "Dashboard")[0].values.toArray();
      const colDepartment = table.fields.filter(a => a.name === "ParentName")[0].values.toArray();
      const mmsShutdownReq = table.fields.filter(a => a.name === "MMSShutdownRequired")[0].values.toArray();
      const mmsShortDescription = table.fields.filter(a => a.name === "MMSShortDescription")[0].values.toArray();
      const mmsDescription = table.fields.filter(a => a.name === "MMSDescription")[0].values.toArray();
      const mmsStart = table.fields.filter(a => a.name === "MMSStartDate")[0].values.toArray();
      const mmsEnd = table.fields.filter(a => a.name === "MMSEndDate")[0].values.toArray();
      const mmsNumber = table.fields.filter(a => a.name === "MMSNumber")[0].values.toArray();
      const mmsStatus = table.fields.filter(a => a.name === "MMSStatus")[0].values.toArray();
      const team = table.fields.filter(a => a.name === "Team")[0].values.toArray();
      const path = table.fields.filter(a => a.name === "Path")[0].values.toArray();
      const scale = table.fields.filter(a => a.name === "Scale")[0].values.toArray();
      const animations = table.fields.filter(a => a.name === "Animations")[0].values.toArray();
      const colHasImage = table.fields.filter(a => a.name === "HasImage")[0].values.toArray();
      const colShowERPData = table.fields.filter(a => a.name === "ShowERPData")[0].values.toArray();
      const colRestrictedAccess = table.fields.filter(a => a.name === "RestrictedAccess")[0].values.toArray();
      const colPosZ = table.fields.filter(a => a.name === "PosZ")[0].values.toArray();


      machines = table.fields.filter(a => a.name === "OrgItemID")[0].values.toArray().map((r: number, i) => {
        const m: LayoutMachine = {
          orgItemId: r,
          sensorId: (!r) ? colName[i] : colSensor[i],
          name: (colName[i]) ? colName[i] : colSensor[i],
          posX: colPosX[i],
          posY: colPosY[i],
          posZ: colPosZ[i],
          width: colWidth[i],
          height: colHeight[i],
          depth: colDepth[i],
          color: 'rgba(0,0,0,0.4)',
          status: 'UNKNOWN',
          URL: colURL[i] !== undefined && colURL[i] !== null && colURL[i].length > 0
            ? OptionsHelper.readWithVariables(this.props.options.dashboardURLpart1, this.props) + colURL[i] + OptionsHelper.readWithVariables(this.props.options.dashboardURLpart2, this.props) + colSensor[i]
            : "",
          alarm: false,
          face: colFace[i],
          floor: colFloor[i],
          static: (!r),
          metric: [],
          contexts: [],
          rotate: colRotate[i],
          type: colType[i],
          department: colDepartment[i],
          visible: true,
          mms: null,
          team: team[i],
          path: path[i],
          scale: scale[i],
          animations: this.getAnimations(animations[i]),
          hasImage: colHasImage[i],
          showERPData: colShowERPData[i],
          restrictedAccess: colRestrictedAccess[i],
          metricSprites: []
        };
        if (mmsShutdownReq[i] !== null && !m.restrictedAccess) {
          const mms: MMSData = {
            shutdownRequired: mmsShutdownReq[i],
            description: mmsDescription[i],
            shortDescription: mmsShortDescription[i],
            startDate: mmsStart[i],
            endDate: mmsEnd[i],
            number: mmsNumber[i],
            started: mmsStatus[i] === this.props.options.maintenanceStartedStatus,
            history: mmsStatus[i] === this.props.options.maintenanceHistoryStatus,
          };
          m.mms = mms;
        }
        return m;
      });
    }
    this.machines = this.applyFilterOnMachines(machines);
  }

  getAnimations(animationsString: string): MachineAnimationConfig[] {
    const result: MachineAnimationConfig[] = [];
    if (animationsString !== null && animationsString !== "") {
      const animationConfiguration = JSON.parse(animationsString);
      Object.keys(animationConfiguration).forEach(e => {
        let statusAnimations = [];
        if (animationConfiguration[e]) {
          statusAnimations = animationConfiguration[e].split(',');
        }
        result.push({ id: parseInt(e, 10), status: statusAnimations });
      });
    }

    return result;
  }

  applyFilterOnMachines(machines: LayoutMachine[]): LayoutMachine[] {
    machines = this.fixMachinesMMS(machines);
    this.filterListDepartment = this.buildFilterListDepartment(machines);
    this.filterListTeam = this.buildFilterListTeam(machines);
    if (this.state.filterValueDepartment !== 'ALL' || this.state.filterValueTeam !== 'ALL') {
      machines.forEach(m => {
        if (this.state.filterValueTeam === 'ALL') {
          if (m.department === this.state.filterValueDepartment) {
            m.visible = true;
          } else {
            m.visible = false;
          }
        }

        if (this.state.filterValueDepartment === 'ALL') {
          if (m.team === this.state.filterValueTeam) {
            m.visible = true;
          } else {
            m.visible = false;
          }
        }

        if (this.state.filterValueDepartment !== 'ALL' && this.state.filterValueTeam !== 'ALL') {
          if (m.team === this.state.filterValueTeam && m.department === this.state.filterValueDepartment) {
            m.visible = true;
          } else {
            m.visible = false;
          }
        }
      });
    } else {
      machines.forEach(m => {
        m.visible = true;
      });
    }
    return machines;
  }

  fixMachinesMMS(machines: LayoutMachine[]): LayoutMachine[] {
    let dict = new Map<string, LayoutMachine>();
    machines.forEach(m => {
      if (!dict.has(m.sensorId) || m.mms?.started || !m.mms?.history) { dict.set(m.sensorId, m); }
    });
    return Array.from(dict.values());
  }

  getContextsFromQuery(): OrderContext[] {
    const contexts: OrderContext[] = [];
    const table = this.props.data.series.find(s => s.refId === 'D' || s.refId === "Context");
    if (table !== undefined && table.fields.length > 0) {
      const colSensor = table.fields[0].values.toArray();
      const wo = table.fields[1].values.toArray();
      const pos = table.fields[2].values.toArray();
      const posName = table.fields[3].values.toArray();
      const op = table.fields[4].values.toArray();
      const opName = table.fields[5].values.toArray();
      const operator = table.fields[6].values.toArray();
      const from = table.fields[7].values.toArray();
      const to = table.fields[8].values.toArray();
      const goodQty = table.fields[9].values.toArray();
      const scrapQty = table.fields[10].values.toArray();
      const orderedQty = table.fields[11].values.toArray();
      const opPlanStop = table.fields[12].values.toArray();
      const goodQuantityShift = table.fields[13].values.toArray();
      const scrapQuantityShift = table.fields[14].values.toArray();
      const timePerItem = table.fields[15].values.toArray();
      const setupTime = table.fields[16].values.toArray();
      const elapsedTime = table.fields[17].values.toArray();
      const totalGanttTime = table.fields[18].values.toArray();

      colSensor.forEach((r: string, i) => {
        const v: OrderContext = {
          sensorId: r,
          wo: wo[i],
          pos: pos[i],
          posName: posName[i],
          op: op[i],
          opName: opName[i],
          operator: operator[i],
          from: from[i],
          to: to[i],
          goodQty: goodQty[i],
          scrapQty: scrapQty[i],
          orderedQty: orderedQty[i],
          opPlanStop: opPlanStop[i],
          shiftGoodQty: goodQuantityShift[i],
          shiftScrapQty: scrapQuantityShift[i],
          timePerItem: timePerItem[i],
          setupTime: setupTime[i],
          elapsedTime: elapsedTime[i],
          totalGanttTime: totalGanttTime[i]
        };
        contexts.push(v);
      });
    }
    return contexts;
  }

  getSetupinspectionFromQuery(): SetupInspection[] {
    const setupInspections: SetupInspection[] = [];
    const table = this.props.data.series.find(s => s.refId === 'F' || s.refId === "SetupInspection");
    if (table !== undefined && table.fields.length > 0) {
      const status = table.fields[0].values.toArray();
      const orderPositionOperation = table.fields[1].values.toArray();
      const machine = table.fields[5].values.toArray();
      const ready = table.fields[7].values.toArray();
      const completed = table.fields[8].values.toArray();
      const itemNo = table.fields[10].values.toArray();
      const drawing = table.fields[11].values.toArray();

      status.forEach((r: string, i) => {
        const v: SetupInspection = {
          Status: r,
          OrderPositionOperation: orderPositionOperation[i],
          Machine: machine[i],
          Ready: ready[i],
          Completed: completed[i],
          ItemNo: itemNo[i],
          Drawing: drawing[i],
        };
        setupInspections.push(v);
      });
    }
    return setupInspections;
  }

  getConfigurationFromQuery(): LayoutConfiguration {
    const table = this.props.data.series.find(s => s.refId === 'E' || s.refId === "Layout");
    const result: LayoutConfiguration = {
      mapWidth: 2000,
      mapHeight: 1000,
      floors: []
    }
    if (table !== undefined && table.fields.length > 0) {
      const colName = table.fields.filter(a => a.name === "Name")[0].values.toArray();
      const colValue = table.fields.filter(a => a.name === "Value")[0].values.toArray();
      colName.forEach((name, i) => {
        if (name === "MapWidth") {
          result.mapWidth = colValue[i];
        }
        if (name === "MapHeight") {
          result.mapHeight = colValue[i];
        }
        if (name.startsWith("Floor")) {
          const floor: FloorConfiguration = {
            url: colValue[i],
            floor: parseInt(name.replace("Floor", ""), 10),
            name:name
          };
          result.floors.push(floor);
        }
      });
    }
    return result;
  }

  checkOnlineStatus(status: any, alarms: any): any {
    const filterText = OptionsHelper.readWithVariables(this.props.options.notOnlineStatusFilter, this.props);
    if (!filterText) {
      return alarms;
    }
    const notOnlineStatuses = filterText.split(',');
    if (notOnlineStatuses.includes(status)) {
      return null;
    } else {
      return alarms;
    }
  }
}
