import React from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, LegacyForms, ColorPicker } from '@grafana/ui';
import { MetricTypeDefinition, ThresholdDefinition, ColorMode, ValueMode, PositionOnMachine } from 'types';

export const MetricSetter: React.FC<StandardEditorProps<MetricTypeDefinition[]>> = ({
  item,
  value,
  onChange,
  context,
}) => {
  const onThresholdChanged = (metricDefinitionIndex: number, threshold: ThresholdDefinition, index: number) => {
    const newMetricArr = value;
    newMetricArr[metricDefinitionIndex].thresholds[index] = threshold;

    onChange(newMetricArr);
  };

  const removeThreshold = (metricDefinitionIndex: number, index: number) => {
    const newMetricArr = value;

    const newThresholdsArr = newMetricArr[metricDefinitionIndex].thresholds;
    newThresholdsArr.splice(index, 1);
    newMetricArr[metricDefinitionIndex].thresholds = newThresholdsArr;

    onChange(newMetricArr);
  };

  const onMetricTypeDefinitionChanged = (metricDefinition: MetricTypeDefinition, metricDefinitionIndex: number) => {
    const newMetricArr = value;
    newMetricArr[metricDefinitionIndex] = metricDefinition;

    onChange(newMetricArr);
  };


  const addMetricTypeDefinition = () => {
    const newMetricArr = value;
    const newMetric: MetricTypeDefinition = {
      tagType: '',
      sensorIdRegEx: '',
      displayName: '',
      min: 0,
      max: 100,
      unit: '',
      color: '#5664f9',
      thresholds: [],
      decimals: 1,
      colorMode: ColorMode.Gauge,
      valueMode: ValueMode.Gauge,
      expression: "",
      timeFormat: "",
      defaultGaugeColor: '#ffffff',
      defaultValueColor: '#ffffff',
      defaultTitleColor: '#ffffff',
      displayOnMachine: false,
      positionOnMachine: PositionOnMachine.TopLeft,
      fontSize: 30
    };
    newMetricArr.push(newMetric);

    onChange(newMetricArr);
  };

  const removeMetricTypeDefinition = (index: number) => {
    const newMetricArr = value;
    newMetricArr.splice(index, 1);

    onChange(newMetricArr);
  };

  const addThreshold = (metricDefinitionIndex: number) => {
    const newMetricArr = value;
    const newThresholdsArr = value[metricDefinitionIndex].thresholds;
    newThresholdsArr.push({ color: '#ffffff', value: 100 });

    newMetricArr[metricDefinitionIndex].thresholds = newThresholdsArr;

    onChange(newMetricArr);
  };


  const renderMetricType = (item: MetricTypeDefinition, index: number) => {
    return (
      <div className="section gf-form-group">
        <div className="gf-form">
          <span className="gf-form-label">
            <i className="fa fa-remove pointer" onClick={() => removeMetricTypeDefinition(index)}></i>
          </span>
          &nbsp;
          <LegacyForms.FormField
            label="Display name"
            labelWidth={200}
            inputWidth={200}
            type="text"
            value={item.displayName}
            onChange={(e: any) => {
              item.displayName = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
          &nbsp;
          <LegacyForms.FormField
            label="Tag type"
            labelWidth={200}
            inputWidth={200}
            type="text"
            value={item.tagType}
            onChange={(e: any) => {
              item.tagType = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="SensorID RegEx"
            labelWidth={100}
            inputWidth={400}
            type="text"
            tooltip="If set, this Metric Type will be applied only to Sensor IDs matching this Regular Expression (no slashes required e.g. 'M00\d')"
            value={item.sensorIdRegEx}
            onChange={(e: any) => {
              item.sensorIdRegEx = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="Min"
            labelWidth={100}
            inputWidth={100}
            type="number"
            value={item.min}
            onChange={(e: any) => {
              item.min = e.target.valueAsNumber;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
          &nbsp;
          <LegacyForms.FormField
            label="Max"
            labelWidth={100}
            inputWidth={100}
            type="number"
            value={item.max}
            onChange={(e: any) => {
              item.max = e.target.valueAsNumber;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="Decimals"
            labelWidth={100}
            inputWidth={100}
            type="number"
            value={item.decimals}
            onChange={(e: any) => {
              item.decimals = e.target.valueAsNumber;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
          &nbsp;
          <LegacyForms.FormField
            label="Unit"
            labelWidth={100}
            inputWidth={100}
            type="text"
            value={item.unit}
            onChange={(e: any) => {
              item.unit = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="Color"
            labelWidth={100}
            inputWidth={100}
            type="text"
            value={item.color}
            onChange={(e: any) => {
              item.color = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
          &nbsp;
          <span className="gf-form-label">
            <ColorPicker
              color={item.color}
              onChange={(e: any) => {
                item.color = e;
                onMetricTypeDefinitionChanged(item, index);
              }}
            ></ColorPicker>
          </span>
          &nbsp;
          <span className="gf-form-label">Color mode</span>
          <select
            title="colorMode"
            onChange={(e: any) => {
              item.colorMode = Number(e.target.value);
              onMetricTypeDefinitionChanged(item, index);
            }}
          >
            <option value="1" selected={item.colorMode === ColorMode.Gauge}>Gauge</option>
            <option value="2" selected={item.colorMode === ColorMode.Value}>Value</option>
            <option value="3" selected={item.colorMode === ColorMode.Title}>Title</option>
          </select>
        </div>
        <div className="gf-form">
          <span className="gf-form-label">Value mode</span>
          <select
            title="valueMode"
            onChange={(e: any) => {
              item.valueMode = Number(e.target.value);
              onMetricTypeDefinitionChanged(item, index);
            }}
          >
            <option value="1" selected={item.valueMode === ValueMode.Gauge}>Gauge</option>
            <option value="2" selected={item.valueMode === ValueMode.Value}>Value</option>
            <option value="3" selected={item.valueMode === ValueMode.Time}>Time</option>
            <option value="4" selected={item.valueMode === ValueMode.TimeAndValue}>Time & Value</option>
          </select>
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="Time format"
            labelWidth={100}
            inputWidth={400}
            type="text"
            tooltip="Default: browser local date time format. Formatting is custom. Usual formats can be used. Only Browser/Default and UTC timezones are supported"
            value={item.timeFormat}
            onChange={(e: any) => {
              item.timeFormat = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.FormField
            label="Expression"
            labelWidth={100}
            inputWidth={400}
            type="text"
            tooltip="Supported placeholders: {Value}, {Time}, {Type}, {SensorID}. Metric queries should return field with names IntValue, FloatValue, StringValue"
            value={item.expression}
            onChange={(e: any) => {
              item.expression = e.target.value;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="gf-form">
          <LegacyForms.Switch
            label="Display on machine"
            tooltip="The value will be displayed on the machine in the layout."
            checked={item.displayOnMachine}
            onChange={(e: any) => {
              item.displayOnMachine = e.target.checked;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
          &nbsp;
          <span className="gf-form-label">Position on machine</span>
          <select
            title="positionOnMachine"
            onChange={(e: any) => {
              item.positionOnMachine = Number(e.target.value);
              onMetricTypeDefinitionChanged(item, index);
            }}
          >
            <option value="1" selected={item.positionOnMachine === PositionOnMachine.TopLeft}>Top Left</option>
            <option value="2" selected={item.positionOnMachine === PositionOnMachine.TopCenter}>Top Center</option>
            <option value="3" selected={item.positionOnMachine === PositionOnMachine.TopRight}>Top Right</option>
            <option value="4" selected={item.positionOnMachine === PositionOnMachine.MiddleLeft}>Middle Left</option>
            <option value="5" selected={item.positionOnMachine === PositionOnMachine.MiddleCenter}>Middle Center</option>
            <option value="6" selected={item.positionOnMachine === PositionOnMachine.MiddleRight}>Middle Right</option>
            <option value="7" selected={item.positionOnMachine === PositionOnMachine.BottomLeft}>Bottom Left</option>
            <option value="8" selected={item.positionOnMachine === PositionOnMachine.BottomCenter}>Bottom Center</option>
            <option value="9" selected={item.positionOnMachine === PositionOnMachine.BottomRight}>Bottom Right</option>
          </select>
          &nbsp;
          <LegacyForms.FormField
            label="FontSize"
            labelWidth={80}
            inputWidth={50}
            type="number"
            value={item.fontSize}
            onChange={(e: any) => {
              item.fontSize = e.target.valueAsNumber;
              onMetricTypeDefinitionChanged(item, index);
            }}
          />
        </div>
        <div className="section gf-form">
          <h5 className="section-heading">Thresholds</h5>
          {item.thresholds.map((t, i) => renderThresholds(t, index, i))}
          <Button onClick={e => addThreshold(index)}>Add threshold</Button>
        </div>
        <br />
      </div>
    );
  };

  const renderThresholds = (threshold: ThresholdDefinition, metricDefinitionIndex: number, index: number) => (

    <div className="gf-form">
      <span className="gf-form-label">
        <i className="fa fa-remove pointer" onClick={() => removeThreshold(metricDefinitionIndex, index)}></i>
      </span>
      &nbsp;
      <LegacyForms.FormField
        label="Value"
        labelWidth={100}
        inputWidth={100}
        type="text"
        value={threshold.value}
        onChange={(e: any) =>
          onThresholdChanged(metricDefinitionIndex, { color: threshold.color, value: e.target.valueAsNumber }, index)
        }
      />
      &nbsp;
      <span className="gf-form-label">
        <i className="fa fa-arrow-right"></i>
      </span>
      &nbsp;
      <LegacyForms.FormField
        label="Color"
        labelWidth={200}
        inputWidth={200}
        type="text"
        value={threshold.color}
        onChange={(e: any) =>
          onThresholdChanged(metricDefinitionIndex, { color: e.target.value, value: threshold.value }, index)
        }
      />
      &nbsp;
      <span className="gf-form-label">
        <ColorPicker
          color={threshold.color}
          onChange={(e) => onThresholdChanged(metricDefinitionIndex, { color: e, value: threshold.value }, index)}
        ></ColorPicker>
      </span>
    </div>
  );

  return (
    <div>
      {value.map((s: MetricTypeDefinition, i) => renderMetricType(s, i))}
      <br />
      <Button onClick={addMetricTypeDefinition}>Add metric type</Button>
    </div>
  );
};
